#footer{
    margin-bottom: 3rem;
    padding: 0 3rem ;
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3rem;
    color: #E8EBEA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#socials>a>i{
    color: white;
}
