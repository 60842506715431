 #prisma{
    text-align: left;
     max-width: 65vw;
     font-size: 1.5vw;
     margin: auto;
     font-weight: normal;
     justify-content:normal ;
     align-items: normal;
}
.titles{
    font-family: "gambado-sans", sans-serif;
    color: #729b79;
    font-size: 3vw;
}
 .credits{
     display: grid;
     grid-template-columns: repeat(2,50%);
     text-align: left;
     gap: 2rem;
     line-height: 30px;
     margin-bottom: 1rem;
     font-size: 1.5vw;
 }
