*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar{
    width: 1vw;
}

/* Track */
::-webkit-scrollbar-track {
  background: #0f0f1b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5F737B;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #A2B3B2;
}
#root{
  background: #0f0f1b;
  min-height: 100vh;
  overflow: hidden;
}

