/*MAIN PAGE*/
#home{
    color: #fafbf6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/*INTRO*/

.outer-div {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.background-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.fade-in {
    animation-name: fadeIn;
}

.fade-out {
    animation-name: fadeOut;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 2rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    padding: 1rem;

}
#name{
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3vw;
}
#title{
    font-family: "JetBrains Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    font-size: 1vw;
    letter-spacing: 1px;
}
/*INTRO END*/



#buttons-wrapper{
    width: 100%;
    font-family: "lores-12", sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 5vw;
    gap: 1vw 3vw;
    display: grid;
    grid-template-columns: repeat(6,6vw);
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 2vw;
    margin-bottom: 5vw;

}
#buttons-wrapper>li>img{
    width: 4vw;
    height:4vw;
    object-fit: contain;
}

#buttons{
    font-family: "indivisible", sans-serif;
    font-weight: bold;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    text-shadow: none;
}

#buttons > a{
    text-decoration: none;
    background: #E8EBEA;
    color: #565a75;
    padding: 1rem;
    border-radius: .3rem;
    font-size: 1.5vw;
}

#buttons > a:hover{
    background: #565a75;
    color: #f69197;
}
.recent-projects {
    font-family: "Russo One", sans-serif;
    font-style: normal;
    display: grid;
    grid-template-columns: repeat(10,auto);
    gap: 1vw;
    margin-top: 1.5vh;
    overflow-x: scroll;
    overflow-Y:hidden ;
    padding: 2rem;
    margin-bottom: 5vw;
}

.thumbnail-description{
    font-size: 1vw;
    font-family: "JetBrains Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}

.home-container{
    display:inline-block ;
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: column;
    scroll-behavior: smooth;
    overflow-y:hidden ;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 1rem 3rem ;
}

.home-thumbnail{
    position: relative;
    display: inline-block;
    width: 30vw;
    height: 50vh;
    background-size: contain;
    text-align: center;
    text-decoration: none;
    color: #E8EBEA;
    transition: ease-in 0.3s;

}
.home-thumbnail:hover{
    -webkit-box-shadow: 0px 0px 39px -6px rgba(162,179,178,1);
    -moz-box-shadow: 0px 0px 39px -6px rgba(162,179,178,1);
    box-shadow: 0px 0px 39px -6px rgba(162,179,178,1);
}

.home-thumbnail>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.thumbnail-text-wrapper {
    width: 100%;
    object-fit: contain;
    position: absolute;
    bottom: 0;
    text-align: left;
    padding: 5vh 3vh;
    font-size: 1.2vw;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(15, 15, 27, 1) 60%);
}
#thumbnail-text-wrapper>h3{
    font-family: "lores-12", sans-serif;
    font-weight: 900;
    font-style: normal;
}
.hidden{
    display: none;
}

.home-description{
    font-weight: normal;
    font-size: 1vw;
    padding: .5rem;

}
#socials{
   display: flex;
    justify-content: left;
    gap: 1rem;
    align-items: center;

}
#socials > a{
    text-decoration: none;
    color:  rgb(86,90,117);
    border-radius: .3rem;
}
#footer{
    margin-bottom: 3rem;
    font-size: 4vh;
    padding: 0 3rem ;
}
.about-me-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.about-me{
    width: 50vw;
    background: white;
    color: black;
    font-family: "JetBrains Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
    padding: 3rem;
    font-size: .9vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

#profile-pic{
    width: 25%;
    object-fit: contain;
    border-radius: 100%;
}
#video{
    object-fit: cover;
    width: 100vw;
}

.line{
    width:50vw;
    margin:0 auto;
    margin-bottom: 1vw;
}

.section-titles{
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #fafbf6;
    margin-bottom: 0.5rem;
    margin-top: 7rem;
}

#cv-button{
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    font-style: normal;
    border: none;
    color: #fafbf6;
    background: #5F737B;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
}
#cv-button:hover{
    cursor: pointer;
    background: #A53551;
}
@media only screen and (max-width: 1024px) {
    #intro{
        display: flex;
        flex-direction: column;

    }
    #recent-projects {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    #home-thumbnail{
        width: 80vw;
        height: 30vh;
    }
    #name{
        font-size: 6vw;
    }
    #title{
        font-size: 2vw;
    }
    .section-titles{
        font-size: 4vw;
    }
    .line{
        width:80vw;
    }
    .about-me{
        width: 80vw;
        font-size: 2vw;

    }
    #buttons-wrapper{
        font-size: 7vw;
        gap: 2vw 5vw;
    }



}

@media only screen and (max-width: 768px) {
    #intro{
        margin-top: 4rem;
        min-height: 50vh;
    }
    #buttons>a{
        font-size: 3vw; ;
    }
    .recent-projects {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }
    .home-thumbnail{
        width: 70vw;
        height: 30vh;


    }
    #name{
        font-size: 8vw;
    }
    #title{
        font-size: 3vw;
        text-wrap: normal;
    }

}