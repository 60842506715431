.two-pictures{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100%;
    max-height: 100%;
    gap: 1rem;
    margin-bottom: 2rem;
}

.two-pictures > img{
    width: 100%;

}



@media only screen and (max-width: 1000px) {
    .two-pictures{
        flex-direction: column;
    }


}