.navbar-wrapper{
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    min-width: 100%;
    top:0;
    height:auto;
    position: fixed;
    font-family: "Doppio One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1vw;
    background: #000;
}

.navbar-wrapper a{
    text-decoration: none;
    color: #fafbf6;
}

.navbar{
    display: grid;
    grid-template-columns: repeat(5,auto);
    grid-gap: 1rem;

}
.navbar a:hover:after{
    display: block;
    content: '';
    width: 100%;
    height: 3px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
}

.navbar a{
    position: relative;
    color:  #fafbf6;
    padding: 8px;
}

.nav-btn{
    font-size: 3vh;
    position: absolute;
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline:none;
    color: #f1f1f1;
    visibility: hidden;
    opacity: 0 ;
}

@media only screen and (max-width: 1024px) {
    header .nav-btn{
        position: relative;
        visibility: visible;
        opacity: 1 ;

    }
    .navbar-wrapper{
        font-size: 5vh;

    }
    header .navbar{
        position: fixed;
        top:0;
        right: 0;
        height: 50%;
        width:100%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1vh;
        transition: 1s;
        transform: translateY(-100vh);
        color: #729b79;
        background:#000;
    }
    .navbar a{
        color: white;
    }
    header .responsive-nav {
        transform: none;
    }
    .navbar a:after{
        display: block;
        content: '';
        width: 100%;
        height: 3px;
        background: #fff;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .navbar-wrapper{
        align-items: flex-end;
        justify-content: flex-end;
    }

}