.page{
    font-family: "indivisible", sans-serif;
    font-weight: bold;
    font-style: normal;
    color: #E8EBEA;
    display: flex;
    flex-direction: column;
    padding: 7rem 3rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.thumbnail-images{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.popUp-images-container{
    width: 100%;
    height: 60vh;
}

.popup-images{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.description-container{
    display: inline-block;

}
.carousel{

}
.skills-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: .5rem;
}

.thumbnail{
    gap: 40px;
}

.skills{
    background: #f69197;
    color: #fff;
    padding: .2rem;
    border-radius: .2rem;

}

.popup-description{
    font-family: "indivisible", sans-serif;
    font-weight: normal;
    font-style: normal;
    text-align: start;
    font-size: 1rem;
    white-space: normal;
}

.title{
    font-family: "lores-12", sans-serif;
    font-weight: 900;
    font-style: normal;
    width: 100%;
    object-fit: contain;
    position: absolute;
    bottom: 0;
    text-align: left;
    padding: 5vh 3vh;
    font-size: 1.5vw;
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 5%, rgba(15, 15, 27, 1) 50%);
}

.gd{
    width: 25rem;
    height: 23rem;
    background-size: contain;
    position: relative;
    text-align: center;
    text-decoration: none;
    color: #E8EBEA;
    transition: ease-in 0.3s;
    background: rgba(255, 255, 255, 0.7);
    margin: 1rem;
    cursor: pointer;
    border: none;
}



@media only screen and (max-width: 965px) {
    .page{

        padding: 7rem 1rem;

    }


}