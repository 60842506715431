.other-thumbnail{
    display: flex;
    width: 100%;
    text-align: left;
    padding: 2rem;
    font-size: 1vw;

}
.other-image{
    width: 100%;
    height: 70%;
    object-fit: cover;
}
.other-thumbnail:hover{
    background: rgba(255,255,255,0.5);
}